'use client';
import Image from 'next/image';

import { MAIN_PAGE_STATISTICS } from '../main-page.data';

import { useTranslation } from 'next-i18next';

import DownloadButtons from 'ui/download-buttons/download-buttons';
import { SignUpButton } from 'ui/sign-up-button/sign-up-button';

import styles from './sign-up.module.scss';

const SignUp = () => {
  const { t } = useTranslation('main');

  return (
    <div className={styles.wrapper}>
      <div className={styles.preview}>
        <div className={styles.preview__left_block}>
          <h2>{t('One Account, Endless Possibilities')}</h2>
          <SignUpButton className={styles.signUp__button} />
          <div className={styles.preview__left_block__links}>
            <DownloadButtons googleHeight={40} googleWidth={137} />
          </div>
        </div>
        <div className={styles.preview__icon}>
          <Image
            alt='sign-up-right-block-image'
            height={402}
            src='/img/main/sign-up-right-block-image.png'
            width={644}
          />
        </div>
      </div>

      <div className={styles.statistic}>
        {MAIN_PAGE_STATISTICS?.map((item) => (
          <div key={item.label} className={styles.statistic__item}>
            <h3>{item.count}</h3>
            <span>{t(item.label)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignUp;
